import { Browser } from 'js/shared/Browser.js';
import { json_to_urlencoded } from 'js/shared/form-helpers.js';
const vendor = ((navigator && navigator.vendor) || '').toLowerCase();
const user_agent = ((navigator && navigator.userAgent) || '').toLowerCase();

window.onerror = function (msg, _path, line, column, error) {
    let data = {
        error: error ? error.stack : '',
        source: _path,
        column: column,
        line: line,
        msg: msg,
        ua: user_agent,
        version: (navigator && navigator.appVersion) || '',
        vendor: vendor,
        browser: Browser.getBrowserName(),
        page: location.href,
    };
    fetch('/api/js-error', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: json_to_urlencoded(data),
    });
    return false;
};

// document.addEventListener('click', (e) => {
//     const data = {
//         window_width: window.innerWidth,
//         window_height: window.innerHeight,
//         x: e.layerX,
//         y: e.layerY,
//         page: new URL(location.href).pathname,
//     };
//     if (e.target.href && e.target.href !== '#') {
//         e.preventDefault();
//     }
//     fetch('/public/record_heatmap.php', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: json_to_urlencoded(data),
//     }).then((_) => {
//         if (e.target.href && e.target.href !== '#') {
//             window.location = e.target.href;
//         }
//     });
//     return false;
// });
